import {OverwolfApi, OWUserSessionEventType} from "@devour/client";
import getConfig from "@/utils/getConfig";

export async function logOverwolfUserEvent(eventType: OWUserSessionEventType): Promise<void> {
    try {
        await new OverwolfApi(getConfig()).logOWUserEvent({
            createOWUserEventBody: {
                eventType,
            },
        });
    } catch (e) {}
}