/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    OWGame,
    OWGameFromJSON,
    OWGameFromJSONTyped,
    OWGameToJSON,
    OWGameEventType,
    OWGameEventTypeFromJSON,
    OWGameEventTypeFromJSONTyped,
    OWGameEventTypeToJSON,
    OWLogCategory,
    OWLogCategoryFromJSON,
    OWLogCategoryFromJSONTyped,
    OWLogCategoryToJSON,
    OWRewardType,
    OWRewardTypeFromJSON,
    OWRewardTypeFromJSONTyped,
    OWRewardTypeToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection contains the rewards that can be claimed.
 * @export
 * @interface OWReward
 */
export interface OWReward {
    /**
     * 
     * @type {OWGame}
     * @memberof OWReward
     */
    game?: OWGame;
    /**
     * The title of the reward.
     * @type {string}
     * @memberof OWReward
     */
    name: string;
    /**
     * The description of the reward.
     * @type {string}
     * @memberof OWReward
     */
    description?: string;
    /**
     * 
     * @type {OWRewardType}
     * @memberof OWReward
     */
    rewardType: OWRewardType;
    /**
     * The amount of reward that can be claimed.
     * @type {number}
     * @memberof OWReward
     */
    rewardAmount: number;
    /**
     * The maximum amount of reward that can be claimed.
     * @type {number}
     * @memberof OWReward
     */
    rewardMax?: number;
    /**
     * The metadata of the reward.
     * @type {object}
     * @memberof OWReward
     */
    rewardMetaData?: object;
    /**
     * 
     * @type {OWLogCategory}
     * @memberof OWReward
     */
    rewardCategory: OWLogCategory;
    /**
     * 
     * @type {OWGameEventType}
     * @memberof OWReward
     */
    eventType: OWGameEventType;
    /**
     * The progress towards the target.
     * @type {number}
     * @memberof OWReward
     */
    progress?: number;
    /**
     * The target amount of progress to reach to claim the reward.
     * @type {number}
     * @memberof OWReward
     */
    target: number;
    /**
     * The timestamp when the event was created.
     * @type {Date}
     * @memberof OWReward
     */
    createdAt: Date;
    /**
     * The timestamp when the event was last updated.
     * @type {Date}
     * @memberof OWReward
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof OWReward
     */
    id: string;
}

export function OWRewardFromJSON(json: any): OWReward {
    return OWRewardFromJSONTyped(json, false);
}

export function OWRewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWReward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'game': !exists(json, 'game') ? undefined : OWGameFromJSON(json['game']),
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'rewardType': OWRewardTypeFromJSON(json['rewardType']),
        'rewardAmount': json['rewardAmount'],
        'rewardMax': !exists(json, 'rewardMax') ? undefined : json['rewardMax'],
        'rewardMetaData': !exists(json, 'rewardMetaData') ? undefined : json['rewardMetaData'],
        'rewardCategory': OWLogCategoryFromJSON(json['rewardCategory']),
        'eventType': OWGameEventTypeFromJSON(json['eventType']),
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
        'target': json['target'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
    };
}

export function OWRewardToJSON(value?: OWReward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'game': OWGameToJSON(value.game),
        'name': value.name,
        'description': value.description,
        'rewardType': OWRewardTypeToJSON(value.rewardType),
        'rewardAmount': value.rewardAmount,
        'rewardMax': value.rewardMax,
        'rewardMetaData': value.rewardMetaData,
        'rewardCategory': OWLogCategoryToJSON(value.rewardCategory),
        'eventType': OWGameEventTypeToJSON(value.eventType),
        'progress': value.progress,
        'target': value.target,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
    };
}


