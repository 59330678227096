/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OWUserSessionEventType,
    OWUserSessionEventTypeFromJSON,
    OWUserSessionEventTypeFromJSONTyped,
    OWUserSessionEventTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateOWUserEventBody
 */
export interface CreateOWUserEventBody {
    /**
     * 
     * @type {OWUserSessionEventType}
     * @memberof CreateOWUserEventBody
     */
    eventType: OWUserSessionEventType;
}

export function CreateOWUserEventBodyFromJSON(json: any): CreateOWUserEventBody {
    return CreateOWUserEventBodyFromJSONTyped(json, false);
}

export function CreateOWUserEventBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOWUserEventBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventType': OWUserSessionEventTypeFromJSON(json['eventType']),
    };
}

export function CreateOWUserEventBodyToJSON(value?: CreateOWUserEventBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventType': OWUserSessionEventTypeToJSON(value.eventType),
    };
}


