import {ReactElement, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import getConfig from "@/utils/getConfig";
import { UsersApi } from "@devour/client";
import {useParams} from "react-router";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {toggleChatOpen} from "@/redux/meta/metaActions";

export enum Chatbots {
    ORDER_SUPPORT = "devour_go_order_support",
    GENERAL_SUPPORT = "devour_support",
    DEVOURPLAY_SUPPORT = "devour_play_support",
}

declare global {
    interface Window {
        Atlas: any;
    }
}

function AtlasChatWidget(): ReactElement {
    const dispatch = useDispatch();
    const {menuOrderId} = useParams<{ menuOrderId?: string }>();
    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const isChatOpen = useSelector((store: IStore) => store.metaStore.isChatOpen);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);

    useEffect(() => {
        const isAllowedUser: boolean = checkAllowedUser();
        if (
            (import.meta.env.VITE_ENVIRONMENT === "production" || import.meta.env.VITE_ENVIRONMENT.includes("staging")) &&
            isAllowedUser
        ) {
            // If Env is prod or staging AND user is allowed (non dev team or not logged in), then init Atlas
            initAtlas();
        } else if (
            import.meta.env.VITE_ENVIRONMENT.includes("develop") ||
            import.meta.env.VITE_ENVIRONMENT.includes("local") ||
            !isAllowedUser
        ) {
            // Disable Atlas for all users on develop and local, or for devs
            console.log("Atlas not initializing, env = ", import.meta.env.VITE_ENVIRONMENT);
        } else {
            // Should not get here, but just in case we should enable by default.
            console.log("Atlas initializing by default, env = ", import.meta.env.VITE_ENVIRONMENT);
            initAtlas();
        }
    }, []);

    useEffect(() => {
        if (isChatOpen) {
            openChatWindow();
        }
    }, [isChatOpen]);

    useEffect(() => {
        registerMenuOrder();
    }, [menuOrder]);

    useEffect(() => {
        void identifyUser();
    }, [fullToken?.id]);

    function initAtlas() {
        if (!window.Atlas) {
            let t;
            const e = {
                appId: import.meta.env.VITE_ATLAS_ID,
                v: 2,
                q: [],
                call: function () {
                    // eslint-disable-next-line react/no-this-in-sfc,prefer-rest-params
                    this.q.push(arguments);
                },
            };
            window.Atlas = e;
            const n = document.createElement("script");
            n.async = !0, n.src = "https://app.atlas.so/client-js/atlas.bundle.js";
            const s = document.getElementsByTagName("script")[0];
            (t = s.parentNode) === null || void 0 === t || t.insertBefore(n, s);
            window.Atlas.call("start", {
                chat: {
                    hideBubble: true,
                    onWindowVisibilityChange: (visible: boolean) => {
                        if (!visible) {
                            // Set the redux state back to false
                            dispatch(toggleChatOpen(false));
                        }
                    },
                },
            });
        }
    }

    /**
     * Returns true if the current user should be a user that Atlas should record for.
     */
    function checkAllowedUser(): boolean {
        if (currentUser && currentUser.user) {
            return !(
                currentUser.user.email === "cori.williams.913@gmail.com" ||
                currentUser.user.email === "mikepow91@gmail.com" ||
                currentUser.user.email.endsWith("@devour.io")
            );
        }
        return true;
    }

    function registerMenuOrder() {
        if (menuOrder &&
            typeof window.Atlas?.registerCallback === "function" &&
            typeof window.Atlas?.chat?.updateTicketFields === "function") {
            window.Atlas.registerCallback("onNewTicket", (ticket: any) => {
                window.Atlas.chat.updateTicketFields(ticket.ticketId, {
                    devour_order_id: menuOrder.id,
                    handoff: menuOrder.handoff,
                    dsp_id: menuOrder.dspId,
                    first_delivery_id: menuOrder.deliveryId,
                });
            });
        }
    }

    async function identifyUser(): Promise<void> {
        if (fullToken && typeof window.Atlas?.call === "function") {
            const res = await new UsersApi(getConfig(fullToken)).getAtlasHash();
            window.Atlas.call(
                "identify",
                {
                    userId: res.userId,
                    name: res.name,
                    email: res.email,
                    userHash: res.userHash,
                    customFields: {
                        role: "Diner",
                    },
                },
            );

        }
    }

    function openChatWindow(): void {
        if (typeof window.Atlas?.call === "function" && isChatOpen) {
            window.Atlas.call("triggerChatbot", isChatOpen);
        }
        if (typeof window.Atlas?.chat?.openWindow === "function") {
            window.Atlas.chat?.openWindow();
        }
    }

    return null;
}

export default AtlasChatWidget;
